// Import necessary dependencies from React
import React from 'react';
import { BrowserRouter as Switch, Route, Routes, Router } from 'react-router-dom';
// import ThreeDCanvas from './ThreeDCanvas';
// import LoginPage from './LoginPage';
import ScreenshotComponent from './ScreenshotComponent';
import NavBar from './NavBar';
// import NavBar from './NavBar';

// Define your App component
function App() {
  return (
    <div>
      <NavBar/>
      {/* <ThreeDCanvas/> */}
      <ScreenshotComponent/>
    </div>
  );
}

// Export the App component
export default App;
