 import React from 'react';
import html2canvas from 'html2canvas';
import ThreeDCanvas from './ThreeDCanvas';

const ScreenshotComponent = () => {
  const handleScreenshot = () => {
    const targetElement = document.getElementById('screenshotTarget');

    html2canvas(targetElement).then(function (canvas) {
    document.body.appendChild(canvas);
      const downloadLink = document.createElement('a');
      downloadLink.href = canvas.toDataURL('image/png');
      downloadLink.download = 'screenshot.png';

      downloadLink.click();
    });
  };

  return (
    <div>
      <div className='screen'>
      <button className="Save" onClick={handleScreenshot}>Save</button>
      <h1>3D Canvas</h1>
      </div>


      <div>
      </div>
      <div id="screenshotTarget">
        <ThreeDCanvas />
      </div>
    </div>
  );
};

export default ScreenshotComponent; 