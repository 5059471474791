import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './App.css';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader';
import * as dat from 'dat.gui';
import html2canvas from 'html2canvas';


function ThreeDCanvas() {
    // const canvasRef = useRef(null);
    console.log('Rendering Login component');
    useEffect(() => {
        // if (process.env.NODE_ENV === 'production'){
        // Create a new renderer
        const renderer = new THREE.WebGL1Renderer();

        // Set the size of the renderer
        renderer.setSize(window.innerWidth, window.innerHeight);

        // Append the renderer's domElement to the body
        document.body.appendChild(renderer.domElement);

        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);

        const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);

        const orbit = new OrbitControls(camera, renderer.domElement);
        orbit.update();

        // const axesHelper = new THREE.AxesHelper(5);
        // scene.add(axesHelper);
        camera.position.set(-10, 30, 30);
        // if (rendererRef) {
        //     rendererRef.current = renderer;
        //   }


        // const planeGeometry = new THREE.PlaneGeometry(30, 30);
        // const planeMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide, wireframe: false });
        // const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        // scene.add(plane);
        // plane.rotation.x = 0.5 * Math.PI;

        // const gridHelper = new THREE.GridHelper(30);
        // scene.add(gridHelper);

        // const options = {
        //     boxColor: '#ffffff',
        //     wireframe: false,
        //     width: boxgeometry.parameters.width
        // };

        const gui = new dat.GUI();

        const gridAndPlaneParameters = {
            showGridAndPlane: true,
          };
      
          const planeGeometry = new THREE.PlaneGeometry(30, 30);
          const planeMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF, side: THREE.DoubleSide, wireframe: false });
          const plane = new THREE.Mesh(planeGeometry, planeMaterial);
          scene.add(plane);
          plane.rotation.x = 0.5 * Math.PI;
      
          const gridHelper = new THREE.GridHelper(30);
          scene.add(gridHelper);
      
          // Add toggle button for grid and plane
          gui.add(gridAndPlaneParameters, 'showGridAndPlane').name('Toggle Grid and Plane').onChange(() => {
            toggleGridAndPlane();
          });
      
          function toggleGridAndPlane() {
            plane.visible = gridAndPlaneParameters.showGridAndPlane;
            gridHelper.visible = gridAndPlaneParameters.showGridAndPlane;
          }

          const axesHelperParameters = {
            showAxesHelper: true,
          };
      
          const axesHelper = new THREE.AxesHelper(5);
          scene.add(axesHelper);
      
          // Add toggle button for axes helper
          gui.add(axesHelperParameters, 'showAxesHelper').name('Toggle Axes Helper').onChange(() => {
            toggleAxesHelper();
          });
      
          function toggleAxesHelper() {
            axesHelper.visible = axesHelperParameters.showAxesHelper;
          }

        const controls = { enableOrbitalControls: () => toggleOrbitalControls() };
        gui.add(controls, 'enableOrbitalControls').name('Toggle Orbital Controls');

        let orbitalControlsEnabled = true; // Set to true initially

        function toggleOrbitalControls() {
            orbitalControlsEnabled = !orbitalControlsEnabled;
            orbit.enabled = orbitalControlsEnabled;
        }
        // Function to create text labels
        function createTextLabel(text, position, size = 1) {
            const spriteMaterial = new THREE.SpriteMaterial();
            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.material.map = createLabelCanvas(text, size);
            sprite.scale.set(1, 0.5, 1);
            sprite.position.copy(position);
            return sprite;
        }

        // Function to create a canvas for the text label
        function createLabelCanvas(text, size = 0.1) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = `${size * 70}px Arial`;
            context.fillStyle = 'black';
            context.fillText(text, 10, 50);
            const texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;
            return texture;
        }
        const options = {
            boxColor: '#ffffff',
            wireframe: false,
            width: 1,
            height: 1,
            depth: 1,
            positionX: 0,
            positionY: 2,
            positionZ: 0,

            sphereColor: '#ffffff',
            wireframe: false,
            radius: 1,
            positionX: 0,
            positionY: 2,
            positionZ: 0,

            coneColor: '#ffffff',
            wireframe: false,
            radius: 1,
            height: 2,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            torusColor: '#ffffff',
            wireframe: false,
            radius: 3,
            tube: 1,
            radialSegments: 8,
            tubularSegments: 24,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            tubeColor: '#ffffff',
            wireframe: false,
            radius: 1,
            tubeRadius: 0.5,
            tubularSegments: 16,
            radialSegments: 100,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            tetrahedronColor: '#ffffff',
            wireframe: false,
            size: 1,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            latheColor: '#ffffff',
            wireframe: false,
            segments: 32,
            radius: 1,
            height: 3,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            circleColor: '#ffffff',
            wireframe: false,
            radius: 1,
            segments: 32,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            cylinderColor: '#ffffff',
            wireframe: false,
            radiusTop: 1,
            radiusBottom: 1,
            height: 3,
            radialSegments: 32,
            heightSegments: 1,
            openEnded: false,
            positionX: 0,
            positionY: 2,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,

            lineColor: '#ff0000',
            lineWidth: 1,
            startPosition: new THREE.Vector3(0, 0, 0),
            endPosition: new THREE.Vector3(1, 1, 1),

            text: 'Hello, World!',
            textColor: '#000000',
            fontSize: 20,
            font: 'Arial',
            positionX: 0,
            positionY: 0,
            positionZ: 0,
            rotationX: 0,
            rotationY: 0,
            rotationZ: 0,
        };

        const addButton = { addBox: function () { addBox(); } };
        gui.add(addButton, 'addBox').name('Add Box');

        function addBox() {
            const AddboxGeometry = new THREE.BoxGeometry(1, 1, 1);
            const AddboxMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newBox = new THREE.Mesh(AddboxGeometry, AddboxMaterial);

            // Set the position for the new box
            newBox.position.set(options.positionX, options.positionY, options.positionZ);

            gui.add(options, 'width').onChange(function (value) {
                newBox.scale.x = value;
                widthLabel.position.x = value / 2;
                widthLabel.material.map = createLabelCanvas(`${value} cm`);
            });

            gui.add(options, 'height').onChange(function (value) {
                newBox.scale.y = value;
                heightLabel.position.y = value / 2;
                heightLabel.material.map = createLabelCanvas(`${value} cm`);
            });

            gui.add(options, 'depth').onChange(function (value) {
                newBox.scale.z = value;
                depthLabel.position.z = value / 2;
                depthLabel.material.map = createLabelCanvas(`${value} cm`);
            });

            // const updateBoxGeometry = function () {
            //     newBox.geometry = new THREE.BoxGeometry(options.width, options.height, options.depth);
            // };
            const widthLabel = createTextLabel('1 cm', new THREE.Vector3(0.5, 0, 0));
            const heightLabel = createTextLabel('2 cm', new THREE.Vector3(0, 1, 0));
            const depthLabel = createTextLabel('3 cm', new THREE.Vector3(0, 0, 1));
            scene.add(widthLabel, heightLabel, depthLabel);

            const updateBoxPosition = function () {
                newBox.position.set(options.positionX, options.positionY, options.positionZ);
            };

            gui.addColor(options, 'boxColor').onChange(function (e) {
                newBox.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newBox.material.wireframe = e;
            });

            // gui.add(options, 'width').onChange(function (w) {
            //     options.width = w;
            //     updateBoxGeometry();
            // });

            // gui.add(options, 'height').onChange(function (h) {
            //     options.height = h;
            //     updateBoxGeometry();
            // });

            // gui.add(options, 'depth').onChange(function (d) {
            //     options.depth = d;
            //     updateBoxGeometry();
            // });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateBoxPosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateBoxPosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateBoxPosition();
            });

            scene.add(newBox);
        }

        const addButton2 = { addSphere: function () { addSphere(); } };
        gui.add(addButton2, 'addSphere').name('Add Sphere');

        function addSphere() {
            const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
            const sphereMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newSphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

            // Set the position for the new sphere
            newSphere.position.set(options.positionX, options.positionY, options.positionZ);

            const updateSphereGeometry = function () {
                newSphere.geometry = new THREE.SphereGeometry(options.radius, 32, 32);
            };

            const updateSpherePosition = function () {
                newSphere.position.set(options.positionX, options.positionY, options.positionZ);
            };

            gui.addColor(options, 'sphereColor').onChange(function (e) {
                newSphere.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newSphere.material.wireframe = e;
            });

            gui.add(options, 'radius').onChange(function (r) {
                options.radius = r;
                updateSphereGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateSpherePosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateSpherePosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateSpherePosition();
            });

            scene.add(newSphere);
        }



        const addButton3 = { addCone: function () { addCone(); } };
        gui.add(addButton3, 'addCone').name('Add Cone');

        function addCone() {
            const coneGeometry = new THREE.ConeGeometry(options.radius, options.height, 32);
            const coneMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newCone = new THREE.Mesh(coneGeometry, coneMaterial);

            // Set the position for the new cone
            newCone.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new cone
            newCone.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateConeGeometry = function () {
                newCone.geometry = new THREE.ConeGeometry(options.radius, 32);
            };

            const updateConePosition = function () {
                newCone.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateConeRotation = function () {
                newCone.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'coneColor').onChange(function (e) {
                newCone.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newCone.material.wireframe = e;
            });

            gui.add(options, 'radius').onChange(function (r) {
                options.radius = r;
                updateConeGeometry();
            });
            const heightLabel = createTextLabel('2 cm', new THREE.Vector3(0, 1, 0));

            // gui.add(options, 'height').onChange(function (h) {
            //     options.height = h;
            //     updateConeGeometry();
            // });
            gui.add(options, 'height').onChange(function (value) {
                newCone.scale.y = value;
                heightLabel.position.y = value / 2;
                heightLabel.material.map = createLabelCanvas(`${value} cm`);
            });
            scene.add(heightLabel);

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateConePosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateConePosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateConePosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateConeRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateConeRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateConeRotation();
            });

            scene.add(newCone);
        }

        const addButton4 = { addTorus: function () { addTorus(); } };
        gui.add(addButton4, 'addTorus').name('Add Ring');

        function addTorus() {
            const torusGeometry = new THREE.TorusGeometry(options.radius, options.tube, options.radialSegments, options.tubularSegments);
            const torusMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newTorus = new THREE.Mesh(torusGeometry, torusMaterial);

            // Set the position for the new torus
            newTorus.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new torus
            newTorus.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateTorusGeometry = function () {
                newTorus.geometry = new THREE.TorusGeometry(options.radius, options.tube, options.radialSegments, options.tubularSegments);
            };
            const TubeLabel = createTextLabel('2 cm', new THREE.Vector3(0, 1, 0));

            gui.add(options, 'tube').onChange(function (value) {
                newTorus.scale.y = value;
                TubeLabel.position.y = value / 2;
                TubeLabel.material.map = createLabelCanvas(`${value} cm`);
            });
            scene.add(TubeLabel);

            const updateTorusPosition = function () {
                newTorus.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateTorusRotation = function () {
                newTorus.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'torusColor').onChange(function (e) {
                newTorus.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newTorus.material.wireframe = e;
            });

            gui.add(options, 'radius').onChange(function (r) {
                options.radius = r;
                updateTorusGeometry();
            });

            gui.add(options, 'tube').onChange(function (t) {
                options.tube = t;
                updateTorusGeometry();
            });

            gui.add(options, 'radialSegments').min(3).step(1).onChange(function (rs) {
                options.radialSegments = rs;
                updateTorusGeometry();
            });

            gui.add(options, 'tubularSegments').min(3).step(1).onChange(function (ts) {
                options.tubularSegments = ts;
                updateTorusGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateTorusPosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateTorusPosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateTorusPosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateTorusRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateTorusRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateTorusRotation();
            });

            scene.add(newTorus);
        }

        // const addButton5 = { addTube: function () { addTube(); } };
        // gui.add(addButton5, 'addTube').name('Add Tube');

        // function addTube() {
        //     const tubeGeometry = new THREE.TubeGeometry(
        //         new THREE.Curve(),
        //         options.tubularSegments,
        //         options.tubeRadius,
        //         options.radialSegments,
        //         false
        //     );
        //     const tubeMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
        //     const newTube = new THREE.Mesh(tubeGeometry, tubeMaterial);

        //     // Set the position for the new tube
        //     newTube.position.set(options.positionX, options.positionY, options.positionZ);

        //     // Set the rotation for the new tube
        //     newTube.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

        //     const updateTubeGeometry = function () {
        //         newTube.geometry = new THREE.TubeGeometry(
        //             new THREE.Curve(),
        //             options.tubularSegments,
        //             options.tubeRadius,
        //             options.radialSegments,
        //             false
        //         );
        //     };

        //     const updateTubePosition = function () {
        //         newTube.position.set(options.positionX, options.positionY, options.positionZ);
        //     };

        //     const updateTubeRotation = function () {
        //         newTube.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
        //     };

        //     gui.addColor(options, 'tubeColor').onChange(function (e) {
        //         newTube.material.color.set(e);
        //     });

        //     gui.add(options, 'wireframe').onChange(function (e) {
        //         newTube.material.wireframe = e;
        //     });

        //     gui.add(options, 'radius').onChange(function (r) {
        //         options.radius = r;
        //         updateTubeGeometry();
        //     });

        //     gui.add(options, 'tubeRadius').onChange(function (tr) {
        //         options.tubeRadius = tr;
        //         updateTubeGeometry();
        //     });

        //     gui.add(options, 'tubularSegments').min(3).step(1).onChange(function (ts) {
        //         options.tubularSegments = ts;
        //         updateTubeGeometry();
        //     });

        //     gui.add(options, 'radialSegments').min(3).step(1).onChange(function (rs) {
        //         options.radialSegments = rs;
        //         updateTubeGeometry();
        //     });

        //     gui.add(options, 'positionX').onChange(function (x) {
        //         options.positionX = x;
        //         updateTubePosition();
        //     });

        //     gui.add(options, 'positionY').onChange(function (y) {
        //         options.positionY = y;
        //         updateTubePosition();
        //     });

        //     gui.add(options, 'positionZ').onChange(function (z) {
        //         options.positionZ = z;
        //         updateTubePosition();
        //     });

        //     gui.add(options, 'rotationX').onChange(function (rx) {
        //         options.rotationX = rx;
        //         updateTubeRotation();
        //     });

        //     gui.add(options, 'rotationY').onChange(function (ry) {
        //         options.rotationY = ry;
        //         updateTubeRotation();
        //     });

        //     gui.add(options, 'rotationZ').onChange(function (rz) {
        //         options.rotationZ = rz;
        //         updateTubeRotation();
        //     });

        //     scene.add(newTube);
        // }

        const addButton6 = { addTetrahedron: function () { addTetrahedron(); } };
        gui.add(addButton6, 'addTetrahedron').name('Add Tetrahedron');

        function addTetrahedron() {
            const tetrahedronGeometry = new THREE.TetrahedronGeometry(options.size);
            const tetrahedronMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newTetrahedron = new THREE.Mesh(tetrahedronGeometry, tetrahedronMaterial);

            // Set the position for the new tetrahedron
            newTetrahedron.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new tetrahedron
            newTetrahedron.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateTetrahedronGeometry = function () {
                newTetrahedron.geometry = new THREE.TetrahedronGeometry(options.size);
            };

            const updateTetrahedronPosition = function () {
                newTetrahedron.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateTetrahedronRotation = function () {
                newTetrahedron.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'tetrahedronColor').onChange(function (e) {
                newTetrahedron.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newTetrahedron.material.wireframe = e;
            });

            gui.add(options, 'size').onChange(function (s) {
                options.size = s;
                updateTetrahedronGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateTetrahedronPosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateTetrahedronPosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateTetrahedronPosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateTetrahedronRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateTetrahedronRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateTetrahedronRotation();
            });

            scene.add(newTetrahedron);
        }

        const addButton7 = { addLathe: function () { addLathe(); } };
        gui.add(addButton7, 'addLathe').name('Add Lathe');

        function addLathe() {
            const points = [];
            for (let i = 0; i < 10; i++) {
                points.push(new THREE.Vector2(Math.sin(i * 0.2) * options.radius + options.radius, (i - 5) * options.height / 10));
            }

            const latheGeometry = new THREE.LatheGeometry(points, options.segments);
            const latheMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newLathe = new THREE.Mesh(latheGeometry, latheMaterial);

            // Set the position for the new lathe
            newLathe.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new lathe
            newLathe.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateLatheGeometry = function () {
                newLathe.geometry = new THREE.LatheGeometry(points, options.segments);
            };

            const updateLathePosition = function () {
                newLathe.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateLatheRotation = function () {
                newLathe.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'latheColor').onChange(function (e) {
                newLathe.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newLathe.material.wireframe = e;
            });

            gui.add(options, 'segments').min(3).step(1).onChange(function (s) {
                options.segments = s;
                updateLatheGeometry();
            });

            gui.add(options, 'radius').onChange(function (r) {
                options.radius = r;
                updateLatheGeometry();
            });

            gui.add(options, 'height').onChange(function (h) {
                options.height = h;
                updateLatheGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateLathePosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateLathePosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateLathePosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateLatheRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateLatheRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateLatheRotation();
            });

            scene.add(newLathe);
        }

        const addButton8 = { addCircle: function () { addCircle(); } };
        gui.add(addButton8, 'addCircle').name('Add Circle');

        function addCircle() {
            const circleGeometry = new THREE.CircleGeometry(options.radius, options.segments);
            const circleMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newCircle = new THREE.Mesh(circleGeometry, circleMaterial);

            // Set the position for the new circle
            newCircle.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new circle
            newCircle.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateCircleGeometry = function () {
                newCircle.geometry = new THREE.CircleGeometry(options.radius, options.segments);
            };

            const updateCirclePosition = function () {
                newCircle.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateCircleRotation = function () {
                newCircle.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'circleColor').onChange(function (e) {
                newCircle.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newCircle.material.wireframe = e;
            });

            gui.add(options, 'radius').onChange(function (r) {
                options.radius = r;
                updateCircleGeometry();
            });

            gui.add(options, 'segments').min(3).step(1).onChange(function (s) {
                options.segments = s;
                updateCircleGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateCirclePosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateCirclePosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateCirclePosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateCircleRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateCircleRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateCircleRotation();
            });

            scene.add(newCircle);
        }

        const addButton9 = { addCylinder: function () { addCylinder(); } };
        gui.add(addButton9, 'addCylinder').name('Add Cylinder');

        function addCylinder() {
            const cylinderGeometry = new THREE.CylinderGeometry(
                options.radiusTop,
                options.radiusBottom,
                options.height,
                options.radialSegments,
                options.heightSegments,
                options.openEnded
            );
            const cylinderMaterial = new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff, wireframe: false });
            const newCylinder = new THREE.Mesh(cylinderGeometry, cylinderMaterial);

            // Set the position for the new cylinder
            newCylinder.position.set(options.positionX, options.positionY, options.positionZ);

            // Set the rotation for the new cylinder
            newCylinder.rotation.set(options.rotationX, options.rotationY, options.rotationZ);

            const updateCylinderGeometry = function () {
                newCylinder.geometry = new THREE.CylinderGeometry(
                    options.radiusTop,
                    options.radiusBottom,
                    options.height,
                    options.radialSegments,
                    options.heightSegments,
                    options.openEnded
                );
            };

            const updateCylinderPosition = function () {
                newCylinder.position.set(options.positionX, options.positionY, options.positionZ);
            };

            const updateCylinderRotation = function () {
                newCylinder.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
            };

            gui.addColor(options, 'cylinderColor').onChange(function (e) {
                newCylinder.material.color.set(e);
            });

            gui.add(options, 'wireframe').onChange(function (e) {
                newCylinder.material.wireframe = e;
            });

            gui.add(options, 'radiusTop').onChange(function (rt) {
                options.radiusTop = rt;
                updateCylinderGeometry();
            });

            gui.add(options, 'radiusBottom').onChange(function (rb) {
                options.radiusBottom = rb;
                updateCylinderGeometry();
            });

            gui.add(options, 'height').onChange(function (h) {
                options.height = h;
                updateCylinderGeometry();
            });

            gui.add(options, 'radialSegments').min(3).step(1).onChange(function (rs) {
                options.radialSegments = rs;
                updateCylinderGeometry();
            });

            gui.add(options, 'heightSegments').min(1).step(1).onChange(function (hs) {
                options.heightSegments = hs;
                updateCylinderGeometry();
            });

            gui.add(options, 'openEnded').onChange(function (oe) {
                options.openEnded = oe;
                updateCylinderGeometry();
            });

            gui.add(options, 'positionX').onChange(function (x) {
                options.positionX = x;
                updateCylinderPosition();
            });

            gui.add(options, 'positionY').onChange(function (y) {
                options.positionY = y;
                updateCylinderPosition();
            });

            gui.add(options, 'positionZ').onChange(function (z) {
                options.positionZ = z;
                updateCylinderPosition();
            });

            gui.add(options, 'rotationX').onChange(function (rx) {
                options.rotationX = rx;
                updateCylinderRotation();
            });

            gui.add(options, 'rotationY').onChange(function (ry) {
                options.rotationY = ry;
                updateCylinderRotation();
            });

            gui.add(options, 'rotationZ').onChange(function (rz) {
                options.rotationZ = rz;
                updateCylinderRotation();
            });

            scene.add(newCylinder);
        }

        const addLineButton = { addLine: function () { addLine(); } };
        gui.add(addLineButton, 'addLine').name('Add Line');

        function addLine() {
            const lineMaterial = new THREE.LineBasicMaterial({ color: options.lineColor, linewidth: options.lineWidth });
            const lineGeometry = new THREE.BufferGeometry().setFromPoints([options.startPosition, options.endPosition]);
            const newLine = new THREE.Line(lineGeometry, lineMaterial);

            const updateLineMaterial = function () {
                newLine.material.color.set(options.lineColor);
                newLine.material.linewidth = options.lineWidth;
            };

            const updateLineGeometry = function () {
                newLine.geometry = new THREE.BufferGeometry().setFromPoints([options.startPosition, options.endPosition]);
            };

            gui.addColor(options, 'lineColor').onChange(function (e) {
                options.lineColor = e;
                updateLineMaterial();
            });

            gui.add(options, 'lineWidth').min(1).step(1).onChange(function (width) {
                options.lineWidth = width;
                updateLineMaterial();
            });

            gui.add(options.startPosition, 'x').onChange(function (x) {
                options.startPosition.x = x;
                updateLineGeometry();
            });

            gui.add(options.startPosition, 'y').onChange(function (y) {
                options.startPosition.y = y;
                updateLineGeometry();
            });

            gui.add(options.startPosition, 'z').onChange(function (z) {
                options.startPosition.z = z;
                updateLineGeometry();
            });

            gui.add(options.endPosition, 'x').onChange(function (x) {
                options.endPosition.x = x;
                updateLineGeometry();
            });

            gui.add(options.endPosition, 'y').onChange(function (y) {
                options.endPosition.y = y;
                updateLineGeometry();
            });

            gui.add(options.endPosition, 'z').onChange(function (z) {
                options.endPosition.z = z;
                updateLineGeometry();
            });

            scene.add(newLine);
        }

        // const addTextButton = { addText: function () { addText(); } };
        // gui.add(addTextButton, 'addText').name('Add Text');

        // function addText() {
        //     const fontLoader = new FontLoader();
        //     let loadedFont;

        //     fontLoader.load('path/to/your/font.json', function (font) {
        //         loadedFont = font;

        //         const textGeometry = new TextGeometry(options.text, {
        //             font: loadedFont,
        //             size: options.fontSize,
        //             height: 1,
        //         });
        //         const textMaterial = new THREE.MeshBasicMaterial({ color: options.textColor });
        //         const newText = new THREE.Mesh(textGeometry, textMaterial);

        //         const updateTextGeometry = function () {
        //             const newGeometry = new TextGeometry(options.text, {
        //                 font: loadedFont,
        //                 size: options.fontSize,
        //                 height: 1,
        //             });
        //             newText.geometry.dispose();
        //             newText.geometry = newGeometry;
        //         };

        //         const updateTextMaterial = function () {
        //             newText.material.color.set(options.textColor);
        //         };

        //         // The rest of the GUI controls remain the same...

        //         newText.position.set(options.positionX, options.positionY, options.positionZ);
        //         newText.rotation.set(options.rotationX, options.rotationY, options.rotationZ);
        //         scene.add(newText);
        //     });
        // }

        const drawingParameters = {
            penColor: 0x0000ff,
            penSize: 0.1,
            clearCanvas: function () {
              clearCanvas();
            },
            penTool: false, // New property to manually toggle the pen tool
          };
      
          // Add pen parameters to Dat.GUI
          const penFolder = gui.addFolder('Pen');
          penFolder.addColor(drawingParameters, 'penColor').onChange(function (value) {
            material.color.set(value);
          });
          penFolder.add(drawingParameters, 'penSize', 0.01, 1);
          penFolder.add(drawingParameters, 'penTool').name('Activate Pen Tool');
      
          let isDrawing = false;
          const mouse = new THREE.Vector2();
          const points = [];
          const material = new THREE.LineBasicMaterial({ color: drawingParameters.penColor });
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const line = new THREE.Line(geometry, material);
          scene.add(line);
      
          renderer.domElement.addEventListener('mousedown', onMouseDown);
          renderer.domElement.addEventListener('mousemove', onMouseMove);
          renderer.domElement.addEventListener('mouseup', onMouseUp);
      
          function onMouseDown(event) {
            isDrawing = true;
            updateMousePosition(event);
          }
      
          function onMouseMove(event) {
            if (!isDrawing || !drawingParameters.penTool) return;
            updateMousePosition(event);
            drawLine();
          }
      
          function onMouseUp() {
            isDrawing = false;
          }
      
          function updateMousePosition(event) {
            const rect = renderer.domElement.getBoundingClientRect();
            mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
          }
      
          function drawLine() {
            const intersection = getIntersection();
            if (intersection) {
              points.push(intersection.clone());
              geometry.setFromPoints(points);
            }
          }
      
          function getIntersection() {
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera(mouse, camera);
      
            const intersects = raycaster.intersectObject(scene, true);
            if (intersects.length > 0) {
              return intersects[0].point;
            }
      
            return null;
          }
      
          function clearCanvas() {
            points.length = 0;
            geometry.setFromPoints(points);
          }
      





        function animate() {
            renderer.render(scene, camera);
        }

        renderer.setAnimationLoop(animate);

        // Cleanup function to handle component unmounting
        // return () => {
        //     // Dispose Three.js objects to prevent memory leaks
        //     if (rendererRef && rendererRef.current) {
        //       rendererRef.current.dispose();
        //     }
        //     scene.dispose();
        //     camera.dispose();
        //     orbit.dispose();
        //   };
        // }

    }, []); // Empty dependency array to run the effect only once

    return <div />;

}

export default ThreeDCanvas;