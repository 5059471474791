import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'


function NavBar() {
  return (
    <nav>
    <div className="logo">
      <img src="/login.jpg" alt="logo" />
    </div>
  </nav>

  )
}

export default NavBar